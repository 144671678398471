import { useEffect, useMemo, useState } from "react";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import { authAxiosInstance } from "../../utils/axiosConfig";
import { toast } from "react-toastify";
import TableWithHeadingAndSearch from "../../components/Table/TableWithHeadingAndSearch";
import moment from "moment";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import { ClipLoader } from "react-spinners";
import { exportCsv } from "../../utils/Utils";
import QueryString from "qs";
import PaginationClassic from "../../components/infrastructure/pagination/PaginationClassic";

const StockReport = () => {
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState({
    inventoryData: false,
    csvData: false,
  });
  const [productInventoryData, setProductInventoryData] = useState({});

  const getProductInventory = async () => {
    try {
      setIsLoading((state) => ({ ...state, inventoryData: true }));
      const string = QueryString.stringify({ page });
      const response = await authAxiosInstance.get(`/report/stock?${string}`);
      if (response) {
        setProductInventoryData(response?.data?.data);
      } else {
        setProductInventoryData({});
      }
      setIsLoading((state) => ({ ...state, inventoryData: false }));
    } catch (error) {
      console.log(error);
      setIsLoading((state) => ({ ...state, inventoryData: false }));
      toast.error("Error Fetching stock Report");
    } finally {
      setIsLoading((state) => ({ ...state, inventoryData: false }));
    }
  };

  useEffect(() => {
    getProductInventory();
  }, [page]);

  const exportCsvDownloadStockData = async () => {
    try {
      setIsLoading((state) => ({ ...state, csvData: true }));
      const string = QueryString.stringify({ limit: 3000, page });
      const response = await authAxiosInstance.get(`/report/stock?${string}`);

      if (response?.data?.data?.docs?.length > 0) {
        let reportData = response?.data?.data?.docs;
        let csvData = reportData.map((item) => {
          return {
            Location: item?.location ? item?.location?.name : "No Sale Found",
            "First In Date": moment(item?.result?.firstIn?.firstInDate).format(
              "DD/MM/YYYY"
            ),
            "First In": item?.result?.firstIn?.firstInReceived,
            "Last In Date": moment(item?.result?.lastIn?.lastInDate).format(
              "DD/MM/YYYY"
            ),
            "Last In": item?.result?.lastIn?.lastInReceived,
            "Inventory Date": moment(item?.result?.currentInventoryDate).format(
              "DD/MM/YYYY"
            ),
            "Inventory Received": item?.result?.currentReceivedInventory,
            "Inventory Damaged": item?.result?.currentDamagedInventory,
            "Master Sku": item?.productData?.masterSku,
            Sale: item?.saleData ? item?.saleData?.totalSale : 0,
          };
        });

        exportCsv(csvData);
      }
      setIsLoading((state) => ({ ...state, csvData: false }));
    } catch (error) {
      console.log(error);
      setIsLoading((state) => ({ ...state, csvData: false }));
      toast.error("Error Downloading data");
    } finally {
      setIsLoading((state) => ({ ...state, csvData: false }));
    }
  };

  const columns = [
    {
      Header: "SRNo.",
      Cell: ({ row }) => {
        return row.index + productInventoryData?.pagingCounter;
      },
    },
    {
      Header: "Location",
      Cell: ({ row }) => {
        return row?.original?.location
          ? row?.original?.location?.name
          : "No Sale Found";
      },
    },
    {
      Header: "First In Date",
      Cell: ({ row }) => {
        return row?.original?.result?.firstIn?.firstInDate
          ? moment(row?.original?.result?.firstIn?.firstInDate).format(
              "DD/MM/YYYY"
            )
          : "No Date Found";
      },
    },
    {
      Header: "First In",
      accessor: "result.firstIn.firstInReceived",
    },
    {
      Header: "Last In Date",
      Cell: ({ row }) => {
        return row?.original?.result?.lastIn?.lastInDate
          ? moment(row?.original?.result?.lastIn?.lastInDate).format(
              "DD/MM/YYYY"
            )
          : "No Date Found";
      },
    },
    {
      Header: "Last In",
      accessor: "result.lastIn.lastInReceived",
    },
    {
      Header: "Inventory Date",
      Cell: ({ row }) => {
        return row?.original?.result?.currentInventoryDate
          ? moment(row?.original?.result?.currentInventoryDate).format(
              "DD/MM/YYYY"
            )
          : "No Date Found";
      },
    },
    {
      Header: "Inventory Received",
      accessor: "result.currentReceivedInventory",
    },
    {
      Header: "Inventory Damaged",
      accessor: "result.currentDamagedInventory",
    },
    {
      Header: "Master Sku",
      accessor: "productData.masterSku",
    },
    {
      Header: "Sale",
      Cell: ({ row }) => {
        return row?.original?.saleData ? row?.original?.saleData?.totalSale : 0;
      },
    },
  ];

  const dataMemoization = useMemo(() => {
    return productInventoryData?.docs ?? [];
  }, [productInventoryData]);

  const columnMemoization = useMemo(() => {
    return columns ?? [];
  }, [columns]);

  return (
    <PageWithCard heading="Stock Report">
      {isLoading.csvData ? (
        <ClipLoader />
      ) : (
        <div className="p-2">
          <PrimaryButton onClick={exportCsvDownloadStockData} type="button">
            Download
          </PrimaryButton>
        </div>
      )}
      {isLoading.inventoryData ? (
        <ClipLoader />
      ) : (
        <>
          <TableWithHeadingAndSearch
            columns={columnMemoization}
            data={dataMemoization}
          />
          <PaginationClassic
            paginationDetails={productInventoryData}
            setPage={setPage}
          />
        </>
      )}
    </PageWithCard>
  );
};

export default StockReport;
